import type { MetaFunction } from '@remix-run/node';
import { useLoaderData } from '@remix-run/react';
import { json } from '@remix-run/server-runtime';
import { query, from, first } from 'linq-functional';

import { MdxCollection } from '@zenobius/content';
import { CmsContent } from '@zenobius/ui-web-cmscontent';
import type { MeDocument } from '@zenobius/content/client';

import { getSiteData } from '../../services/Meta/getSiteData';
import { createSiteMeta } from '../../services/Meta/createSiteMeta';
import { whereSlugEquals } from '../../services/Content/selectors';

async function getContent() {
  try {
    const collection = await MdxCollection.Me;
    const slug = 'me/intro';
    const intro = query(
      from(collection.items),
      whereSlugEquals<MeDocument>(slug),
      first(),
    );

    return intro;
  } catch (error) {
    throw new Error('Failed to load intro data');
  }
}

export async function loader() {
  try {
    const [intro, siteData] = await Promise.all([getContent(), getSiteData()]);

    return json({ intro, siteData });
  } catch (error) {
    throw new Error('Failed to load intro data');
  }
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [...createSiteMeta(data)];
};

export default function IndexRoute() {
  const data = useLoaderData<typeof loader>();
  return <CmsContent content={data.intro.content.compiled} />;
}
